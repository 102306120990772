import styled from 'styled-components';
import { themeGet } from 'styled-system';
import bgheader from '../img/pagebg.jpg';

export const NavWrapper = styled.div`
    background-color: #fff;
    .nav{
          float: right;
          display: inline-flex;
          @media (max-width:500px){
            float: left;
            width: 100%;
          }
    }
    .sticky-nav-active .sticky-inner-wrapper {
        
    }
    .accordion {
        border: 0px;
        .card {
          border: 0px;
        }
    }
    .sticky-inner-wrapper {
        padding: 14px 0px !important;
        background-color: #f4f9fa !important;
        display: grid;
        @media (max-width:500px){
          padding: 0px !important;
        }
    }

    .nav-item {
        border-radius: 7px;
        margin: 0px 0px 0px 10px;
        @media (max-width:500px){
          border-radius: 0px;
          margin: 0px 0px 0px 0px;
          width: 100%;
        }

        a {
            padding: 7px 9px;
            color: #262626;
            font-size: 15px;
            font-family: "Runda W00 Bold" !important;
        }
    }
    .nav-item:hover {
        background-color: #e5e7ec;
    }
    .mobilenav {
        .nav-item {
            display: block;
            width: 100%;
            border-bottom: 1px solid #d2dde8;
            background-color: #f5f8fa;
            padding: 0px 0px;
        }
        .card-header {
            padding: 15px 0px;
         }


    } 

    .card-header {
        padding: 0px 15px;
        background-color: #eaf0f6;
        .btn-link {
          text-decoration: none;
          color: #000;
          font-family: "Runda W00 Bold" !important;
        }
        button {
          width: 100%;
          text-align: left;
        }
        img {
          width: 20px;
          margin-right: 5px;
        }
    }
`;

export const BackgroundImage = styled.div`
    .overlay {
        background-color: rgb(0, 21, 26, 0.6);
        padding: 100px 0px;
    }
   
    background: url(${bgheader});
    color: #fff;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    .post_preview{
      padding-left: 50px;
      :before {
          content: "";
          position: absolute;
          left: 0px;
          width: 4px;
          height: 100%;
          background: linear-gradient(45deg, rgb(32, 211, 64) 0%, rgb(0, 209, 237) 100%);
      }
      .btn-page {
          color: #20d340;
          background-image: none !important;
          padding: 12px 24px;
          font-size: 18px;
          min-height: 50px;
          font-family: "Runda W00 Bold" !important;
          background-color: transparent;
          border-color: transparent;
          :hover {
            background-color: rgba(32, 211, 64, 0.08);
          }
      }
      .firstlink {
        margin-right: 10px;
      } 
    }
    @media (max-width:500px){
        .overlay{
          padding:20px 0px;
        }
      }
`;



export const BannerWrapper = styled.div`
    h1 {
      color: #fff;
      font-size: 4rem;
      font-family: "Runda W00 Bold" !important;
      line-height: 1.14286em;
      margin-left: -.04em;
      letter-spacing: -.04em;
    }
    p {
      font-size: 32px;
      width: 75%;
      padding-top: 18px;
      opacity: 0.9;
      line-height: 1.3em;
    }
    @media (max-width: 600px) {
        h1 {
          font-size: 2rem;
        }
        p {
          font-size: 18px;
        }
      margin-top: 35px;
    }
`;





export const ArticleDetailsWrapper = styled.div`
padding-bottom: 20px;
background-color: #fff;
min-height: 700px;
.sectionblock {
    padding: 80px 0px;
}
.topsubtitle {
        padding-bottom: 35px;
        color: rgba(0, 0, 0, 0.65);
        font-size: .75rem;
        line-height: 1.20588em;
        text-transform: uppercase;
        letter-spacing: .15em;
        width: max-content;
        display: block;
        :after {
            margin-top: 2vh;
            display: block;
            content: "";
            height: 4px;
            background: linear-gradient(45deg, rgb(32, 211, 64) 0%, rgb(0, 209, 237) 100%);
        }
}
.overviewdesc {
  h1{ 
    color: #262626;
    font-size: 2rem;
    font-family: "Runda W00 Bold" !important;
    line-height: 1.13333em;
    margin-left: -.02em;
  }
  h2{ 
    color: #262626;
    font-size: 2rem;
    font-family: "Runda W00 Bold" !important;
    line-height: 1.13333em;
    margin-left: -.02em;
  }
  p{
    font-size: 1rem;
    line-height: 1.71429em;
  }
}
.overviewimg {
  .gatsby-image-wrapper {
    margin-top: 70px;
  }
}
.hide{
  display:none;
}
li {
    line-height: 25px;
}


.post-header__title {

    font-size: 50px !important;
    font-weight: 800 !important;;
    padding: 0px !important;
    line-height: 65px !important;
    text-transform: capitalize;
    font-family: Avenir Next LT Pro Bold !important;
    @media (max-width: 600px) {
         font-size: 20px !important;
          line-height: 27px !important;
          font-weight: 700 !important;
      }
}
.post-author-tag__image-container img {
    width: 85px;
    border-radius: 45px;
    border: 2px solid #e5845a;
    padding: 5px;
    height: 85px;
}
.post-header__meta {
    font-size: 17px;
    span {
      font-weight: 600;
    }
}
.post-author-tag__twitter {
    color: #0091ae;
    font-size: 14px;
    font-weight: 600;
}
.post-author-tag__text {
    padding-left: 0px;
}
.post-author-tag__text {
    padding-top: 15px;
    p {
      color: #000;
      margin-bottom: 3px;
      a {
          color: #000;
      }
    }
  }

.post-author-details-container {
    text-align: center;
    padding: 18px 5px;
    p {
        font-size: 14px;
    }
    button {
      background-color: #e66e50;
      border-color: #e66e50;
      font-size: 14px;
      padding: 9px 24px;
      font-weight: 600;
      &:hover{
        background-color: #e66e50;
      }
    }
}
  position: relative;

  &.image_left {
    @media (min-width: 991px) {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: flex-start;
    }
    .post_preview {
      margin-top: 0;
      @media (min-width: 991px) {
        flex: 0 0 42%;
        max-width: 42%;
        padding-right: 60px;
        margin: 0;
        &:before {
          width: calc(80% - 60px);
        }
      }
    }
    .post_des_wrapper {
      @media (min-width: 991px) {
        flex: 0 0 58%;
        max-width: 58%;
        margin: 0;
      }
      .post_des {
        margin-top: 60px;

      }

    }
  }
`;

export const ArticleTitle = styled.h1`
  font-size: 30px;
  font-weight: 700;
  color: ${themeGet('colors.textColor', '#292929')};
  line-height: 1.53;
  margin-bottom: 10px;
  @media (max-width: 1200px) {
    font-size: 26px;
    margin-bottom: 15px;
  }
  @media (max-width: 990px) {
    font-size: 24px;
    margin-bottom: 15px;
  }
  @media (max-width: 575px) {
    font-size: 20px;
    margin-bottom: 15px;
  }
`;

export const ArticleDate = styled.span`
  padding: 10px 0px;
  text-transform: capitalize;
  display: block;
  font-size: ${themeGet('fontSizes.3', '15')}px;
  color: ${themeGet('textColor', '#292929')};
  font-weight: 400;
  @media (max-width: 990px) {
    font-size: 14px;
  }
  @media (max-width: 575px) {
    font-size: 13px;
  }
`;

export const ArticlePreview = styled.div`
  position: relative;
  @media (max-width: 1200px) {
    margin-top: 40px;
  }
  @media (max-width: 575px) {
    margin-top: 35px;
  }
  img {
    border-radius: 3px;
  }
  
`;

export const ArticleDescriptionWrapper = styled.div`
  .des_tab {
      padding-top: 128px !important;
      margin-top: -128px !important;
  }
  margin-top: 15px;
  max-width: 100%;
  @media (max-width: 1200px) {
    margin-top: 70px;
  }
  @media (max-width: 575px) {
    margin-top: 30px;
  }
`;

export const ArticleDescription = styled.div`
      
    font-size: 17px;
    letter-spacing: 0.6px;
    color: #4c4a4a;
    line-height: 30px;

  .mermaid {
    margin-bottom: 60px;
    @media (max-width: 767px) {
      margin-bottom: 40px;
    }
  }

  p {
    font-size: 0.95em;
    color: #33475b;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: 500;
    margin-bottom: 0.75em;
  }

  h1 {
    font-size: 30px;
    margin-bottom: 0.75em;
    font-weight: bold;
  }

  h2 {
    font-size: 25px;
    margin-bottom: 0.30em;
    margin-top: 0.75em;
  }

  h3 {
    font-size: 21px;
    margin-bottom: 0.30em;
    margin-top: 0.75em;
  }

  h4 {
    font-size: 17px;
    margin-bottom: 0.30em;
    margin-top: 0.75em;
  }

  h5 {
    font-size: 15px;
    margin-bottom: 0.30em;
    margin-top: 0.75em;
  }

  h6 {
    font-size: 13px;
    margin-bottom: 0.30em;
    margin-top: 0.75em;
  }

  ol,
  ul {
    margin-left: 1.5rem;
    margin-top: 1rem;
  }
  li {
      list-style: unset;
      line-height: 15px;
      @media (max-width: 767px) {
        line-height: 25px;
      }
    p {
      margin-bottom: 1em;
    }
  }

  blockquote {
    font-family: 'Poppins', sans-serif;
    font-size: 21px;
    font-weight: 500;
    line-height: 2;
    margin: 60px 0;
    @media (max-width: 1200px) {
      margin: 50px 0;
      font-size: 19px;
    }
    @media (max-width: 575px) {
      margin: 40px 0;
      font-size: 15px;
    }
    &:before,
    &:after {
      content: '';
      width: 30px;
      height: 1px;
      display: block;
      background: #292929;
    }
    &:before {
      margin-bottom: 60px;
      @media (max-width: 1200px) {
        margin-bottom: 50px;
      }
      @media (max-width: 575px) {
        margin-bottom: 40px;
      }
    }
    &:after {
      margin-top: 60px;
      @media (max-width: 1200px) {
        margin-top: 50px;
      }
      @media (max-width: 575px) {
        margin-top: 40px;
      }
    }

    p {
      font-size: 21px;
      font-weight: 500;
      line-height: 2;
      margin-bottom: 23px;
      @media (max-width: 1200px) {
        font-size: 19px;
      }
      @media (max-width: 1200px) {
        font-size: 17px;
      }
      @media (max-width: 575px) {
        font-size: 15px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
    h4 {
      font-size: 16px;
      margin: 0;
      font-family: 'Fira Sans', sans-serif;
      font-weight: 400;
    }
  }

  a {
    font-weight: 500;
    transition: 0.15s ease-in-out;
    color: ${themeGet('primary', '#D10068')};
  }


`;
