import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PageDetails from "../components/PageDetails/pageDetails"
const PagePostTemplate = (props) => {

const post = props.data.contentfulPages

  return (
    <Layout rel="canonical">
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
      />
        <PageDetails
          title={post.title}
          description={post.childContentfulPagesBodyRichTextNode}
          bannertitle={post.pageBannerTitle}
          bannersubtitle={post.pageBannerSubTitle}
          buttononecaption={post.pageBannerButtonOneCaption}
          buttononeLink={post.pageBannerButtonOneLink}
          buttontwocaption={post.pageBannerButtonTwoCaption}
          buttontwoLink={post.pageBannerButtonTwoLink}
          overviewtitle={post.overviewTabTitle}
          overviewdesc={post.overviewTabDescriptionTitle}
          overviewimg={
                      post.overviewTabImage === null
                      ? "noimg"
                      : post.overviewTabImage.fluid
          }
          tab1={post.tabOneTitle}
          tabdesc={post.tabOneDescription}
          tab2={post.tabTwoTitle}
          tabdesc2={post.tabTwoDescription}
          tab3={post.tabThreeTitle}
          tabdesc3={post.tabThreeDescription}
          tab4={post.tabFourTitle}
          tabdesc4={post.tabFourDescription}
          tab5={post.tabFiveTitle}
          tabdesc5={post.tabFiveDescription}
          pagetype={post.pageType}
        />
    </Layout>
  )
}

export default PagePostTemplate

export const pageQuery = graphql`
  query PagePostBySlug($slug: String!) {
    contentfulPages(
      slug: { eq: $slug }
      node_locale: { eq: "en-US" }
    ) 
    {
      metaTitle
      metaDescription
      title
      childContentfulPagesBodyRichTextNode {
          json
      }
      pageBannerTitle
      pageBannerSubTitle
      pageBannerButtonOneCaption
      pageBannerButtonOneLink
      pageBannerButtonTwoCaption
      pageBannerButtonTwoLink
      overviewTabTitle
      overviewTabDescriptionTitle {
          json
      }
      overviewTabImage {
          fluid(maxWidth: 1500){
            ...GatsbyContentfulFluid_withWebp
          }
      }
      tabOneTitle
      tabOneDescription{
          json
      }
      tabTwoTitle
      tabTwoDescription{
          json
      }
      tabThreeTitle
      tabThreeDescription{
          json
      }
      tabFourTitle
      tabFourDescription{
          json
      }
      tabFiveTitle
      tabFiveDescription{
          json
      }
      pageType
    }
  }`

