import React from 'react'
import Img from "gatsby-image"
import { Nav, Row, Col, Button, Container, Accordion, Card } from "react-bootstrap"
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import Sticky from 'react-stickynode';
import MenusvgIcon from '../img/menu-button-of-three-horizontal-lines.svg';
import { Icon } from 'react-icons-kit';
import {longArrowRight} from 'react-icons-kit/fa/longArrowRight';
import {
  ArticleDetailsWrapper,
  ArticlePreview,
  ArticleDescriptionWrapper,
  ArticleDescription,
  BannerWrapper,
  BackgroundImage,
  NavWrapper,
} from "./pageDetails.style"


const ArticleDetails = ({
  title,
  description,
  bannertitle,
  bannersubtitle,
  buttononecaption,
  buttononeLink,
  buttontwocaption,
  buttontwoLink,
  overviewtitle,
  overviewdesc,
  overviewimg,
  tab1,
  tabdesc,
  tab2,
  tabdesc2,
  tab3,
  tabdesc3,
  tab4,
  tabdesc4,
  tab5,
  tabdesc5,
  urlpath,
  pagetype,
   ...props
}) => {

  let options = {
    renderNode: {
      'embedded-asset-block': (node) =>
        `<img class="img-fluid" src="${node.data.target.fields.file['en-US'].url}"/>`
    }
  }

  return (
    <ArticleDetailsWrapper {...props} className="post_details">
          <BannerWrapper>
            <BackgroundImage>
              <div className="overlay">
                  {title === null ? null : (
                     <Container>
                        { pagetype ? (
                        <ArticlePreview className="post_preview">
                          <Row>
                            <Col md={12} sm={12} className="post-header__text">
                                {bannertitle && bannertitle !== 0 ? (<h1>{bannertitle}</h1>) : ('') }
                                {bannersubtitle && bannersubtitle !== 0 ? (<p>{bannersubtitle}</p>) : ('') }
                                {buttononecaption && buttononecaption !== 0 ? (<a href={buttononeLink} className="firstlink btn btn-light btn-page">{buttononecaption} <Icon icon={longArrowRight} size={16} /></a>) : ('') }
                                {buttontwocaption && buttontwocaption !== 0 ? (<a href={buttontwoLink} className="btn btn-light btn-page">{buttononecaption} <Icon icon={longArrowRight} size={16} /></a>) : ('') }
                            </Col>
                          </Row>
                        </ArticlePreview>
                        ) : (
                        <ArticlePreview className="post_preview">
                          <Row>
                            <Col md={12} sm={12} className="post-header__text">
                                {title && title !== 0 ? (<h1>{title}</h1>) : ('') }
                            </Col>
                          </Row>
                        </ArticlePreview>
                        ) }
                     </Container>
                  )}
              </div>
            </BackgroundImage>
          </BannerWrapper>
          {pagetype ?
            (
            <NavWrapper>
              <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
                <Container>
                  <div className="d-none d-sm-block">
                    <Nav>
                      {overviewtitle && overviewtitle !== 0 ? (
                        <Nav.Item><Nav.Link href="#taboverviewtitle">{overviewtitle}</Nav.Link></Nav.Item>
                      ) : ('') }
                      {tab1 && tab1 !== 0 ? (<Nav.Item><Nav.Link href="#tabOneDescription">{tab1}</Nav.Link></Nav.Item>) : ('') }
                      {tab2 && tab2 !== 0 ? (<Nav.Item><Nav.Link href="#tabTwoDescription">{tab2}</Nav.Link></Nav.Item>) : ('') }
                      {tab3 && tab3 !== 0 ? (<Nav.Item><Nav.Link href="#tabThreeDescription">{tab3}</Nav.Link></Nav.Item>) : ('') }
                      {tab4 && tab4 !== 0 ? (<Nav.Item><Nav.Link href="#tabFourDescription">{tab4}</Nav.Link></Nav.Item>) : ('') }
                      {tab5 && tab5 !== 0 ? (<Nav.Item><Nav.Link href="#tabFiveDescription">{tab5}</Nav.Link></Nav.Item>) : ('') }
                    </Nav>
                  </div>
                </Container>
                  <div className="d-block d-sm-none mobilenav">
                    <Accordion defaultActiveKey="0">
                      <Card>
                        <Card.Header>
                          <Accordion.Toggle as={Button} variant="link" eventKey="1">
                            <img alt="Page Menu icon" src={MenusvgIcon}/> Page Menu
                          </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                          <Nav>
                            {overviewtitle && overviewtitle !== 0 ? (
                              <Nav.Item><Nav.Link href="#taboverviewtitle">{overviewtitle}</Nav.Link></Nav.Item>
                            ) : ('') }
                            {tab1 && tab1 !== 0 ? (<Nav.Item><Nav.Link href="#tabOneDescription">{tab1}</Nav.Link></Nav.Item>) : ('') }
                            {tab2 && tab2 !== 0 ? (<Nav.Item><Nav.Link href="#tabTwoDescription">{tab2}</Nav.Link></Nav.Item>) : ('') }
                            {tab3 && tab3 !== 0 ? (<Nav.Item><Nav.Link href="#tabThreeDescription">{tab3}</Nav.Link></Nav.Item>) : ('') }
                            {tab4 && tab4 !== 0 ? (<Nav.Item><Nav.Link href="#tabFourDescription">{tab4}</Nav.Link></Nav.Item>) : ('') }
                            {tab5 && tab5 !== 0 ? (<Nav.Item><Nav.Link href="#tabFiveDescription">{tab5}</Nav.Link></Nav.Item>) : ('') }
                          </Nav>
                        </Accordion.Collapse>
                      </Card>
                    </Accordion>
                  </div>
              </Sticky>
            </NavWrapper>
            )
            : ('')
          }

          {pagetype ?
          (<ArticleDescriptionWrapper className="post_des_wrapper">
          <Container>
            <div className="overview_block sectionblock" id="taboverviewtitle">
                <Row>
                    <Col md={6} xs={12}>
                      {overviewtitle != null  ? (<span className="topsubtitle">{overviewtitle}</span>) : ('')}
                      {overviewdesc != null  ?
                          (
                            <ArticleDescription
                              dangerouslySetInnerHTML={{ __html: documentToHtmlString(overviewdesc.json, options) }}
                              className="overviewdesc"
                            />
                          )
                          : ('')
                       }
                    </Col>
                    {overviewimg != null ? (
                      <Col md={6} xs={12}>
                        <div className="overviewimg">
                            <Img fluid={overviewimg} />
                        </div>
                      </Col>) : ('') 
                    }
                    
                </Row>
            </div>
          </Container>
          <hr/>
          {tabdesc != null ? ( 
              <Container>
                <div className="sectionblock" id="tabOneDescription">
                <span className="topsubtitle">{tab1}</span>
                  <ArticleDescription 
                    dangerouslySetInnerHTML={{ __html: documentToHtmlString(tabdesc.json, options) }}
                    className="des_tab"
                  />
                </div>
              </Container>
              )
              : ('')
          }
          <hr/>
          {tabdesc2 != null ? (
             <Container>
                <div className="sectionblock" id="tabTwoDescription">
                <span className="topsubtitle">{tab2}</span>
                  <ArticleDescription
                    dangerouslySetInnerHTML={{ __html: documentToHtmlString(tabdesc2.json, options) }}
                    className="des_tab"
                  />
                </div>
            </Container>
            )
            : ('')
          }
          <hr/>
          {tabdesc3 != null ? (
            <Container>
                <div className="sectionblock" id="tabThreeDescription">
                <span className="topsubtitle">{tab3}</span>
                  <ArticleDescription 
                    dangerouslySetInnerHTML={{ __html: documentToHtmlString(tabdesc3.json, options) }}
                    className="des_tab"
                  /> 
                </div>
            </Container>
            )
            : ('')
          }
          <hr/>
          {tabdesc4 != null ? (
            <Container>
              <div className="sectionblock" id="tabFourDescription">
              <span className="topsubtitle">{tab4}</span>
                  <ArticleDescription
                    dangerouslySetInnerHTML={{ __html: documentToHtmlString(tabdesc4.json, options) }}
                    className="des_tab"
                  />
              </div>
            </Container>
            )
            : ('')
          }
          <hr/>
          {tabdesc5 != null ? (
            <Container>
              <div className="sectionblock" id="tabFiveDescription">
              <span className="topsubtitle">{tab5}</span>
                  <ArticleDescription
                    dangerouslySetInnerHTML={{ __html: documentToHtmlString(tabdesc5.json, options) }}
                    className="des_tab"
                  />
              </div>
            </Container>
            )
            : ('')
          }
         </ArticleDescriptionWrapper>
          
       )  : (
        <ArticleDescriptionWrapper className="post_des_wrapper">
           <Container> 
              <div>
                {description != null  ?
                  (
                    <ArticleDescription
                      dangerouslySetInnerHTML={{ __html: documentToHtmlString(description.json, options) }}
                      className="post_des"
                    />
                  )
                  : ('')
                }
              </div>
           </Container>
        </ArticleDescriptionWrapper>)
     }
    </ArticleDetailsWrapper>
  )
}

ArticleDetails.defaultProps = {
  imagePosition: "top",
}

export default ArticleDetails
